<template>
  <div class="mt-4  px-5">

    <h2>Terminal-Verwaltung</h2>


    <v-data-table
        :headers="headers"
        :items="comp_Terminals"
        :expanded.sync="comp_Terminals.shooting_ranges"
        single-expand
        item-key="id"
        :sort-by="['created_at']"
        :sort-desc="[true]"
        class="elevation-1"
        :loading="comp_Progress_loading"
        loader-height="8"
        show-expand
    >
      <template v-slot:top>
        <v-toolbar
            flat
            color="success">
          <h3>Terminals</h3>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              max-width="600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon class="mr-2">mdi-pistol</v-icon>
                Neues Terminal
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                <span class="text-h5">Neues Terminal</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-form ref="form" v-on:submit.prevent>
                  <v-container>
                    <v-row>
                      <v-col cols="3">
                        <v-subheader class="mySubheader">Terminal Name</v-subheader>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                            v-model="New_Terminal.name"
                            :error-messages="errorMessages"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                        <v-subheader class="mySubheader">Stände</v-subheader>
                      </v-col>
                      <v-col cols="8">
                        <v-select
                            v-model="New_Terminal.ranges"
                            :items="comp_Ranges"
                            :menu-props="{ maxHeight: '400' }"
                            label="Stände"
                            item-text="LangBez"
                            item-value="id"
                            value="id"
                            key="id"
                            multiple
                            hint="Stände zuordnen, die vom Terminal verwalten werden soll"
                            persistent-hint
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="dialog = false"
                >
                  Abbrechen
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="add_new_Terminal"
                >
                  Speichern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>


          <v-btn icon
                 @click="getTerminals">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.created_at="{ item }">
        <span>{{
            new Date(item.created_at.replace(" ", "T")).toLocaleString([], {
              dateStyle: 'short',
              timeStyle: 'short'
            })
          }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
            class="info my-1 mx-1"
            text @click="openEditDialog(item)">
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
            class="error my-1"
            text @click="openDelete_Dialog(item)">
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>


      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-simple-table
              dense
              class="mt-3 mb-3">
            <template v-slot:default>
              <thead>
              <span>Zugeordnete Stände</span>
              <tr>
                <th class="text-left">Kurz</th>
                <th class="text-left">Name</th>
                <th class="text-left">Bahnen</th>
                <!-- Add more headers if necessary -->
              </tr>
              </thead>
              <tbody>
              <tr v-for="range in item.shooting_ranges" :key="range.id">
                <td>{{ range.KurzBez }}</td>
                <td>{{ range.LangBez }}</td>
                <td>{{ range.totalplaces }}</td>
                <!-- Add more cells if necessary -->
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>

    </v-data-table>


    <v-dialog
        v-model="dialog_update"
        max-width="600px">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <span class="text-h5">Terminal ändern</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeUpdate">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form_update" v-on:submit.prevent>
            <v-container>
              <v-row>
                <v-col cols="3">
                  <v-subheader class="mySubheader">Terminal ID</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="Edit_Terminal.id"
                      :error-messages="errorMessages"
                      readonly
                      disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-subheader class="mySubheader">Terminal Name</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="Edit_Terminal.name"
                      :error-messages="errorMessages"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-subheader class="mySubheader">Stände</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-select
                      v-model="Edit_Terminal.shooting_ranges"
                      :items="comp_Ranges"
                      :menu-props="{ maxHeight: '400' }"
                      label="Stände"
                      item-text="LangBez"
                      item-value="id"
                      value="id"
                      key="id"
                      multiple
                      hint="Stände zuordnen, die vom Terminal verwalten werden soll"
                      persistent-hint
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="closeUpdate"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="update_Terminal"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Willst du diesen Eintrag wirklich löschen?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItem()">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
export default {
  name: "Terminal",
  components: {},
  data: () => ({
    dialog: false,
    dialog_update: false,
    dialogDelete: false,
    dialogSign: false,
    loading: false,
    item_delete: null,
    errorMessages: '',
    New_Terminal: {
      name: '',
      ranges: []
    },
    Edit_Terminal: {},
    headers: [
      {
        align: 'ID',
        sortable: false,
      },
      {text: 'Name', value: 'name'},
      {text: 'ID', value: 'id'},
      {text: 'erstellt', value: 'created_at'},
      {text: 'Action', value: 'actions', sortable: false, align: 'center'},
    ],
    signature_style: {border: 'black 3px solid'},
    prepare_delete_item: {}
  }),

  computed: {
    comp_Terminals() {
      return this.$store.state.ClubApp.Terminals;
    },
    comp_Progress_loading() {
      return this.loading;
    },
    comp_Ranges() {
      return this.$store.state.ClubApp.ShootingRanges;
    },

  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  mounted() {
    this.initialize();
  },

  methods: {

    initialize() {
      this.getTerminals()
      this.getRanges()
    },

    getTerminals() {
      this.$store.dispatch('ClubApp/getTerminals')
    },

    async add_new_Terminal() {
      this.loading = true;
      const Data = {
        client_name: this.New_Terminal.name,
        shooting_ranges: this.New_Terminal.ranges
      };

      await this.$store.dispatch('ClubApp/setNewTerminal', Data)
          .then(
              this.loading = false,
              this.dialog = false
          )
    },

    openEditDialog(item) {
      console.log(item);
      this.Edit_Terminal = {};
      this.Edit_Terminal = item;
      this.dialog_update = true;
    },

    async update_Terminal() {
      this.loading = true;
      const Data = {
        client_id: this.Edit_Terminal.id,
        client_name: this.Edit_Terminal.name,
        shooting_ranges: this.Edit_Terminal.shooting_ranges
      };

      if (this.$refs.form_update.validate()) {
        await this.$store.dispatch('ClubApp/updateTerminal', Data)
            .then(
                this.loading = false,
                this.dialog_update = false
            )
        this.$refs.form_update.resetValidation();
        this.$refs.form_update.reset();
        this.errorMessages = '';

        this.Edit_Terminal = {}
      }
    },

    async deleteItem() {
      this.loading = true;
      const Data = {
        client_id: this.item_delete.id
      };

      await this.$store.dispatch('ClubApp/setDelTerminal', Data)
          .then(
              this.loading = false,
              this.dialogDelete = false
          )
    },

    close() {
      this.dialog = false
    },
    closeUpdate() {
      this.dialog_update = false
    },
    openDelete_Dialog(item) {
      this.item_delete = Object.assign({}, item)
      this.dialogDelete = true
    },
    closeDelete() {
      this.dialogDelete = false
    },

    getRanges() {
      this.$store.dispatch('ClubApp/getRanges')
    },
  },
}

</script>

<style scoped>


</style>

